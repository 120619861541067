<template>
  <div class="pageBack">
    <!--头部-->
    <div class="flex_b_c topDate">
      <div class="dateTimeCls">
        <span>{{ dataDate }}</span>
        <span>{{ weekDay }}</span>
        <span>{{ timeH }}</span>
      </div>
      <div @click="returnPage" class="returnBut">返回</div>
    </div>

    <!--信息-->
    <div class="flex_b_t contentdat">
      <div class="leftCont contentdatItem">
        <div class="moduleBack">
          <div class="moduleContTitle">藏品分类账数</div>
          <div class="numberCollectionLedger" ref="numberCollectionLedger"></div>
        </div>
        <div class="moduleBack moduleTop">
          <div class="moduleContTitle">藏品在库数</div>
          <div class="numberCollectionLedger" ref="numberCollectionsStock"></div>
        </div>
        <div class="moduleBack moduleBack2 moduleTop">
          <div class="moduleContTitle">历史征集线索数</div>
          <div class="numberCollectionLedger" ref="historyCollectionLine"></div>
        </div>
      </div>
      <div class="medilCont contentdatItem">
        <div class="moduleContTitle">藏品总数</div>
        <div class="flex_c_c">
          <!--                    <div v-for="(item,index) in allCollNum" :key="index" class="allNumCar">{{ item }}</div>-->
          <count-flop :val="allCollNum"></count-flop>
        </div>

        <div class="medImgds">
          <el-image
              style="width: 32rem"
              :src="require('@/assets/img/board/boardMediot.png')"
              :fit="'contain'"></el-image>
          <div class="medImgdsCard position1">
            <el-image
                style="width: 3.5rem"
                :src="require('@/assets/img/board/generalNum.png')"
                :fit="'fill'"></el-image>
            <div class="medImgdsCardName">总账数</div>
            <div class="medImgdsCardNum">{{ ledgerAmountNum }}</div>
          </div>
          <div class="medImgdsCard position2">
            <el-image
                style="width: 3.5rem"
                :src="require('@/assets/img/board/solicitationNum.png')"
                :fit="'fill'"></el-image>
            <div class="medImgdsCardName">征集藏品数</div>
            <div class="medImgdsCardNum">{{ collectingCollectionQuantity }}</div>
          </div>
          <div class="medImgdsCard position3">
            <el-image
                style="width: 3.5rem"
                :src="require('@/assets/img/board/auxiliaryNum.png')"
                :fit="'fill'"></el-image>
            <div class="medImgdsCardName">辅助账数</div>
            <div class="medImgdsCardNum">{{ auxiliaryAccounts }}</div>
          </div>
          <div class="medImgdsCard position4">
            <el-image
                style="width: 3.5rem"
                :src="require('@/assets/img/board/exhibitionNum.png')"
                :fit="'fill'"></el-image>
            <div class="medImgdsCardName">展览中</div>
            <div class="medImgdsCardNum">{{ exhibitionNum }}</div>
          </div>
        </div>
      </div>
      <div class="rightCont contentdatItem">
        <div class="moduleBack moduleBack2">
          <div class="moduleContTitle">藏品总数趋势图</div>
          <div class="numberCollectionLedger" ref="collTrendChart"></div>
        </div>
        <div class="moduleBack moduleBack3 moduleTop">
          <div class="moduleContTitle">藏品收藏人数Top10</div>
          <div class="numberCollectionLedger" ref="numberCollectors"></div>
        </div>
        <div class="moduleBack moduleBack2 moduleTop">
          <div class="moduleContTitle">注销藏品数</div>
          <div class="numberCollectionLedger" ref="numberOfCollections"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CountFlop from "@/components/flipper/countFlop.vue";

export default {
  name: "collBoard",
  components: {CountFlop},
  data() {
    return {
      dataDate: '',
      weekDay: '',
      timeH: '',
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      dataInterval: null,

      numberCollectionLedger: null,
      numberCollectionsStock: null,
      historyCollectionLine: null,
      collTrendChart: null,
      numberCollectors: null,
      numberOfCollections: null,
      allCollNum: 0,
      ledgerAmountNum: '',
      collectingCollectionQuantity: '',
      auxiliaryAccounts: '',
      exhibitionNum: '',
    }
  },
  mounted() {
    // setInterval(()=>{
    //     this.allCollNum +=  6
    // },3000)

    //获取时间
    this.getDateTime()

    //获取接口方法
    this.getPageDate()

    //监听浏览器变化
    window.onresize = () => {
      this.numberCollectionLedger.resize()
      this.numberCollectionsStock.resize()
      this.historyCollectionLine.resize()
      this.collTrendChart.resize()
      this.numberCollectors.resize()
      this.numberOfCollections.resize()
    }
  },

  methods: {
    getPageDate() {
      this.getCollLedger()
      this.getCollStock()
      this.getHisLine()
      this.getTrendChart()
      this.getNumbColl()
      this.getCollections()
      this.getAllCount()
    },

    //藏品分类账数
    getCollLedger() {
      this.$axios(this.api.collection.collectionType, {}, 'get').then((res) => {
        if (res.status) {
          let dataArr = []
          for (let i = 0; i < res.data.ydata1.length; i++) {
            dataArr.push({
              value: res.data.ydata1[i],
              name: res.data.x[i],
            })
          }
          this.numberCollectionLedger = this.$echarts.init(this.$refs.numberCollectionLedger)
          let opt = {
            //表盘颜色
            color: ['#74E7E9', '#007CF7', '#17E984', '#F9AB30', '#FBFB89', '#FF7200'],
            //鼠标悬停提示
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)',
              position: ['50%', '60%'],
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },
            //图例
            legend: {
              scrollable: true,
              textStyle: {
                fontSize: 12,
                color: '#ffffff'
              },
              // height:'40px',
              // width: '60%',
              itemHeight: 8,
              itemWidth: 18,
              bottom: -6,
            },
            //图
            series: [
              {
                type: 'pie',
                center: ['50%', '50%'],
                radius: ['40%', '70%'],
                roseType: 'area',
                bottom: 30,
                labelLine: {
                  length: 10
                },
                label: {
                  formatter: '{d}%\n{c}',
                  color: '#D1CFCF',
                  fontSize: 14
                },
                data: dataArr
              }
            ],
          }
          this.numberCollectionLedger.setOption(opt)
        }
      })
    },

    //藏品在库数
    getCollStock() {
      let that = this
      this.$axios(this.api.collection.collectionDataStoreCount, {}, 'get').then((res) => {
        if (res.status) {
          let xArrList = []
          let yArrList1 = []
          for (let i = 0; i < res.data.ydata1.length; i++) {
            xArrList.push(res.data.x[i])
            yArrList1.push(res.data.ydata1[i])
          }
          this.numberCollectionsStock = this.$echarts.init(this.$refs.numberCollectionsStock)
          let colorq = [
            {
              c1: '#59B3F7',
              c2: '#7FFAFC',
            },
            {
              c1: '#F9AB30',
              c2: '#FBFB89',
            },
          ]
          let opt = {
            //鼠标悬停提示
            tooltip: {
              trigger: 'axis',
              // formatter: '{b}: {c}',
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },

            //坐标轴的刻度标签
            grid: {
              top: '15%',
              left: '3%',
              right: '4%',
              bottom: '8',
              containLabel: true
            },

            xAxis: {
              type: 'category',
              offset: 8,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                interval: 0,
                color: '#ffffff',
                fontSize: 12
              },
              data: xArrList
            },
            yAxis: {
              type: 'value',
              nameTextStyle: {
                padding: [0, 40, 0, 0]
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                color: '#ffffff',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  width: 0.3,
                  color: '#ffffff'
                }
              }
            },
            series: {
              name: '藏品数',
              type: 'bar',
              barWidth: 30,  // 设置柱子宽度为30
              label: {
                show: true,
                color: '#ffffff',
                fontSize: 9,
                position: 'top', // 顶部显示
                formatter: '{c}', // 显示数据值
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        offset: 0,
                        color: colorq[params.dataIndex].c1,
                      },
                      {
                        offset: 1,
                        color: colorq[params.dataIndex].c2,
                      },
                    ])
                  },
                  barBorderRadius: [5, 5, 0, 0], // 设置左上角和右上角的圆角为10，右下角和左下角的圆角为0
                },
              },
              data: yArrList1,
            },
          }
          this.numberCollectionsStock.setOption(opt)
        }
      })
    },

    //历史征集线索数
    getHisLine() {
      this.$axios(this.api.collection.collectYearCount, {}, 'get').then((res) => {
        if (res.status) {
          let xArrList = []
          let yArrList = []
          for (let i = 0; i < res.data.ydata1.length; i++) {
            xArrList.push(res.data.x[i])
            yArrList.push(res.data.ydata1[i])
          }
          this.historyCollectionLine = this.$echarts.init(this.$refs.historyCollectionLine)
          let opt = {
            color: ['#7EFAFC'],
            //鼠标悬停提示
            tooltip: {
              trigger: 'axis',
              // formatter: '{b}: {c}',
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },

            //坐标轴的刻度标签
            grid: {
              top: '20%',
              left: '3%',
              right: '4%',
              bottom: '8',
              containLabel: true
            },

            xAxis: {
              type: 'category',
              offset: 8,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                // interval: 20,
                color: '#ffffff',
                fontSize: 12,
              },
              data: xArrList
            },
            yAxis: {
              type: 'value',
              nameTextStyle: {
                padding: [0, 40, 0, 0]
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                color: '#ffffff',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  width: 0.3,
                  color: '#ffffff'
                }
              }
            },
            series: {
              name: '征集线索数',
              type: 'line',
              smooth: true,
              label: {
                show: true,
                fontSize: 9,
                color: '#ffffff',
                position: 'top', // 顶部显示
                formatter: '{c}', // 显示数据值
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0, y: 0, x2: 0, y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(35, 199, 255, 0.8)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(35, 199, 255, 0)' // 100% 处的颜色
                  }]
                }
              },
              data: yArrList,
            },
          }
          this.historyCollectionLine.setOption(opt)
        }
      })
    },

    //藏品总数趋势图
    getTrendChart() {
      this.$axios(this.api.collection.collectTotalCount, {}, 'get').then((res) => {
        if (res.status) {
          let xArrList = []
          let yArrList = []
          for (let i = 0; i < res.data.ydata1.length; i++) {
            xArrList.push(res.data.x[i])
            yArrList.push(res.data.ydata1[i])
          }
          this.collTrendChart = this.$echarts.init(this.$refs.collTrendChart)
          let opt = {
            color: ['#FFDE00'],
            //鼠标悬停提示
            tooltip: {
              trigger: 'axis',
              // formatter: '{b}: {c}',
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },

            //坐标轴的刻度标签
            grid: {
              top: '20%',
              left: '3%',
              right: '4%',
              bottom: '8',
              containLabel: true
            },

            xAxis: {
              type: 'category',
              offset: 8,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                // interval: 20,
                color: '#ffffff',
                fontSize: 12,
              },
              data: xArrList
            },
            yAxis: {
              type: 'value',
              nameTextStyle: {
                padding: [0, 40, 0, 0]
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                color: '#ffffff',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  width: 0.3,
                  color: '#ffffff'
                }
              }
            },
            series: {
              name: '藏品总数',
              type: 'line',
              smooth: true,
              label: {
                show: true,
                fontSize: 9,
                color: '#ffffff',
                position: 'top', // 顶部显示
                formatter: '{c}', // 显示数据值
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0, y: 0, x2: 0, y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(35, 199, 255, 0.8)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(35, 199, 255, 0)' // 100% 处的颜色
                  }]
                }
              },
              data: yArrList,
            },
          }
          this.collTrendChart.setOption(opt)
        }
      })
    },

    //藏品收藏人数Top10
    getNumbColl() {
      let that = this
      this.$axios(this.api.collection.favoritesRank, {}, 'get').then((res) => {
        if (res.status) {
          let xArrList = []
          let yArrList1 = []
          res.data.forEach(item => {
            xArrList.push(item.collection_name)
            yArrList1.push(item.count)
          })
          this.numberCollectors = this.$echarts.init(this.$refs.numberCollectors)
          let opt = {
            legend: {
              show: false,
            },

            //坐标轴的刻度标签
            grid: {
              top: '5%',
              left: '3%',
              right: '10%',
              bottom: '-25',
              containLabel: true
            },

            xAxis: {
              show: false,
              max: 'dataMax',
              axisLine: {
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                interval: 0,
                color: '#ffffff',
                fontSize: 12
              },
            },
            yAxis: {
              type: 'category',
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#ffffff',
                fontSize: 12,
                margin: 8,
                interval: 0, // 强制显示所有名称
                formatter: function (params) {
                  var val = "";
                  // 超过四个字隐藏
                  if (params.length > 4) {
                    val = params.substr(0, 4) + '...';
                    return val;
                  } else {
                    return params;
                  }
                }
              },
              data: xArrList,
              inverse: true,
            },
            series: {
              name: '藏品收藏人数',
              type: 'bar',
              barWidth: 10,  // 设置柱子宽度为30
              label: {
                show: true,
                color: '#ffffff',
                fontSize: 9,
                position: 'right', // 顶部显示
                formatter: '{c}', // 显示数据值
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return new that.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
                      {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        offset: 0,
                        color: '#F9AB30',
                      },
                      {
                        offset: 1,
                        color: '#FBFB89',
                      },
                    ])
                  },
                  barBorderRadius: [5, 5, 5, 5], // 设置左上角和右上角的圆角为10，右下角和左下角的圆角为0
                },
              },
              data: yArrList1,
            },
          }
          this.numberCollectors.setOption(opt)
        }
      })
    },

    //鉴定藏品数
    getCollections() {
      // this.$axios(this.api.collection.identifiedCount, {}, 'get').then((res) => {
      this.$axios(this.api.collection.collectionLogoff, {}, 'get').then((res) => {
        if (res.status) {
          let dataArr = []
          for (let i = 0; i < res.data.x.length; i++) {
            dataArr.push({
              value: res.data.ydata1[i],
              name: res.data.x[i]
            })
          }
          this.numberOfCollections = this.$echarts.init(this.$refs.numberOfCollections)
          let opt = {
            //表盘颜色
            color: ['#154A5C', '#74E7E9'],
            //鼠标悬停提示
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)',
              position: ['50%', '60%'],
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },
            //图
            series: [
              {
                type: 'pie',
                center: ['50%', '55%'],
                radius: ['60%', '100%'],
                top: 20,
                bottom: 14,
                labelLine: {
                  length: 10
                },
                label: {
                  formatter: `{c}\n{b}`,
                  color: '#D1CFCF',
                  fontSize: 12
                },
                data: dataArr
              }
            ],
          }
          this.numberOfCollections.setOption(opt)
        }
      })
    },

    //获取其它总数
    getAllCount() {
      this.$axios(this.api.collection.collectionDataCount, {}, 'get').then((res) => {
        if (res.status) {
          this.allCollNum = res.data.totalCount
          this.ledgerAmountNum = res.data.generalCount
          this.collectingCollectionQuantity = res.data.collectCount
          this.auxiliaryAccounts = res.data.helpCount
          this.exhibitionNum = res.data.exhibitCount
        }
      })
    },

    getDateTime() {
      let datad = new Date()
      let y = datad.getFullYear()
      let m = (datad.getMonth() + 1).toString().padStart(2, '0')
      let d = datad.getDate().toString().padStart(2, '0')
      this.dataDate = y + '年' + m + '月' + d + '日'
      this.weekDay = this.weekdays[datad.getDay()]
      let h = datad.getHours().toString().padStart(2, '0')
      let mm = datad.getMinutes().toString().padStart(2, '0')
      let s = datad.getSeconds().toString().padStart(2, '0')
      this.timeH = h + ':' + mm + ':' + s

      this.dataInterval = setInterval(() => {
        let datad = new Date()
        let y = datad.getFullYear()
        let m = (datad.getMonth() + 1).toString().padStart(2, '0')
        let d = datad.getDate().toString().padStart(2, '0')
        this.dataDate = y + '年' + m + '月' + d + '日'
        this.weekDay = this.weekdays[datad.getDay()]
        let h = datad.getHours().toString().padStart(2, '0')
        let mm = datad.getMinutes().toString().padStart(2, '0')
        let s = datad.getSeconds().toString().padStart(2, '0')
        this.timeH = h + ':' + mm + ':' + s
      }, 1000)
    },

    returnPage() {
      this.$router.back()
    },
  },

  beforeDestroy() {
    clearInterval(this.dataInterval)
  },
}
</script>

<style scoped lang="scss">
.pageBack {
  width: 100%;
  height: 100vh;
  background-image: url("~@/assets/img/board/boardBack.gif");
  background-size: cover;
  background-repeat: no-repeat;
}

.topDate {
  font-weight: 400;
  font-size: 1.02rem;
  color: #FFFFFF;
  padding: 2.56rem 2.31rem 0;

  .dateTimeCls {
    span {
      margin-right: 3.3rem;
    }
  }

  .returnBut {
    cursor: pointer;
    position: relative;
    font-size: 0.8rem;
    padding: 0.2rem 2.2rem 0.2rem 0.6rem;
    border-radius: 0.25rem;
    border: 1px solid #74E7E9;

    &::before {
      content: '';
      position: absolute;
      background-image: url("~@/assets/img/board/return.png");
      display: inline-block;
      right: 0.6rem;
      top: 0.3rem;
      background-size: contain;
      background-repeat: no-repeat;
      width: 1rem;
      height: 1.5rem;
    }
  }
}

.contentdat {
  position: absolute;
  width: 100%;
  bottom: 2rem;
  padding: 0 2rem;

  .contentdatItem {
    height: 100%;
  }

  .leftCont {
    width: 28%;
  }

  .medilCont {
    flex: 1;
  }

  .rightCont {
    width: 28%;
  }

  .moduleBack {
    width: 100%;
    height: 28vh;
    padding: 0.5rem;
    background-image: url("~@/assets/img/board/moduleCont1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: border-box;

    .numberCollectionLedger {
      width: 100%;
      height: calc(100% - 3rem);
    }
  }

  .moduleBack2 {
    height: 23vh;
  }

  .moduleBack3 {
    height: 35vh;
  }

  .moduleTop {
    margin-top: .9rem;
  }
}

.moduleContTitle {
  width: 85%;
  padding: 0.3rem 0.3rem 0.3rem 2.2rem;
  background-image: url("~@/assets/img/board/moduleTitle.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-origin: border-box;

  font-size: 1rem;
  color: #7EFAFC;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-image: url("~@/assets/img/board/moduleTitleIcon.png");
    display: inline-block;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
  }
}

.medilCont {
  position: relative;
  height: 81vh !important;
  padding: 0.5rem 8rem;

  .allNumCar {
    margin: 1.5rem 0.2rem 0;
    text-align: center;
    width: 2.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 2.2rem;
    background-image: url("~@/assets/img/board/medileNumCard.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .medImgds {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-30%);

    .medImgdsCard {
      color: #7EFAFC;
      position: absolute;
      width: 8.71rem;
      height: 9.28rem;
      top: 1rem;
      left: -7rem;
      text-align: center;
      padding: 1rem 0;
      background-image: url("~@/assets/img/board/show.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-origin: border-box;

      .medImgdsCardName {
        font-size: 0.88rem;
      }

      .medImgdsCardNum {
        font-weight: 500;
        font-size: 1.38rem;
        color: #FFFFFF;
      }
    }

    .position2 {
      left: auto;
      right: -7rem;
    }

    .position3 {
      top: auto;
      bottom: 8rem;
    }

    .position4 {
      top: auto;
      bottom: 8rem;
      left: auto;
      right: -7rem;
    }
  }
}
</style>